import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VForm,
        {
          ref: "form",
          attrs: { "lazy-validation": _vm.lazy },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.checkForm()
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c("div", [
            _c("span", { staticClass: "send-code-title" }, [
              _vm._v(_vm._s(_vm.$t("confirm_data"))),
            ]),
            _c("p", { staticClass: "send-code-subtitle" }, [
              _vm._v(" " + _vm._s(_vm.$t("code_sent_text")) + " "),
            ]),
          ]),
          _c(
            "div",
            [
              _c("h3", { staticClass: "email-title" }, [
                _vm._v(_vm._s(_vm.$t("code"))),
              ]),
              _c(VTextField, {
                staticClass: "my-input-style mt-2",
                attrs: {
                  "data-test": "NewUser:ValidateRegister:ConfirmPin",
                  "background-color": "#D1D5DB",
                  color: "#000",
                  solo: "",
                  flat: "",
                  placeholder: "XXXX",
                  rules: [_vm.required],
                  maxLength: "4",
                },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                  },
                },
                model: {
                  value: _vm.code,
                  callback: function ($$v) {
                    _vm.code = $$v
                  },
                  expression: "code",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "recover-btn-container" },
            [
              _c(
                VBtn,
                {
                  staticClass: "recover-btn",
                  attrs: {
                    text: "",
                    color: "primary",
                    loading: _vm.anotherCodeLoading,
                  },
                  on: { click: _vm.sendAnotherCode },
                },
                [_vm._v(_vm._s(_vm.$t("send_another_code")))]
              ),
            ],
            1
          ),
          _c(
            VBtn,
            {
              staticClass: "btn-login",
              attrs: {
                "data-test": "NewUser:ValidateRegister:BtnConclude",
                type: "submit",
                color: "primary",
                loading: _vm.loading,
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("conclude")))])]
          ),
        ],
        1
      ),
      _vm.alert
        ? _c(
            VAlert,
            {
              staticClass: "ma-2",
              attrs: { type: _vm.success ? "success" : "error" },
            },
            [_vm._v(" " + _vm._s(_vm.alert) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }